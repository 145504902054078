import { FC, useEffect, useRef, useState } from 'react'
import { KTCard, toAbsoluteUrl } from '../_dashboard/helpers'
import '../_dashboard/global.css'
import '../_dashboard/index.css'
// import '../_dashboard/home-2.css'
import Slider from 'react-slick'
import { Carousel } from 'antd'
import React from 'react'
import { createOne } from '../app/services/common_requests'
import { ToastContainer, toast } from 'react-toastify'
import { useIntl } from 'react-intl'
import 'react-toastify/dist/ReactToastify.css'
import SlideshowX from './SlideshowX'
import { EngageWidget10 } from '../_dashboard/partials/widgets'
import { Image } from 'react-bootstrap'

const Landing: FC = () => {
    const intl = useIntl()
    const [isOpen, setIsOpen] = useState(false);
    const toggleMenu=()=>{
        setIsOpen((prevState) => !prevState);
    }
    const closeMenu = () => {
        setIsOpen(false);
      };
      const navbarRef = useRef<HTMLDivElement | null>(null);
      useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
          if (navbarRef.current && !navbarRef.current.contains(event.target as Node)) {
            setIsOpen(false);
          }
        };
    
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, []);
    
    const images = [
        {
            src: toAbsoluteUrl('/media/landingpage/image (1).png'),
            caption: 'Caption Text',
        },
        {
            src: toAbsoluteUrl('/media/landingpage/image (2).png'),
            caption: 'Caption Two',
        },
        {
            src: toAbsoluteUrl('/media/landingpage/pexelsgustavofring4254158-1@2x.png'),
            caption: 'Caption Three',
        },
    ]
    const [email, setEmail] = useState('')
    const [nom, setNom] = useState('')
    const [question, setQuestion] = useState('')

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)
    const handleNomChange = (e: React.ChangeEvent<HTMLInputElement>) => setNom(e.target.value)
    const handleQuestionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
        setQuestion(e.target.value)

    const handleSubmit = async (e: React.FormEvent<HTMLButtonElement>) => {
        e.preventDefault()
        const response = await createOne({ email: email, name: nom, message: question }, '/contact').then(
            (response) => {
                if (response === 2021) {
                    toast.success(
                        intl.formatMessage({
                            id: 'ADMIN.COMMON.SUCCESS.CONTACT_ADDED_SUCCESSFULLY',
                        }),
                        {
                            position: 'top-right',
                            autoClose: 4000,
                        }
                    )
                    setEmail('')
                    setNom('')
                    setQuestion('')
                } else {
                    toast.error(
                        intl.formatMessage({
                            id: 'ADMIN.COMMON.ERROR.ERROR_CONTACT_ADDED_SUCCESSFULLY',
                        }),
                        {
                            position: 'top-right',
                            autoClose: 4000,
                        }
                    )
                }
                setEmail('')
                setNom('')
                setQuestion('')
            }
        )
    }
  
    return (
        <>
            <head>
                <meta name='viewport' content='initial-scale=1, width=device-width' />

                <link rel='stylesheet' href='./global.css' />
                <link rel='stylesheet' href='./index.css' />
                <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap'
                />
                <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css2?family=Sora:wght@400;700&display=swap'
                />
                <link
                    rel='stylesheet'
                    href='https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap'
                />
            </head>
            <ToastContainer />

            <div className='landing-page'>
                {/* Header Section */}
                <header className='header'>
                    <div className='hero'>
                        {/* Carousel */}
                        <Carousel autoplay arrows infinite>
                            {images.map((image, index) => (
                                <div key={index} className='content-style'>
                                    <img alt='slider' src={image.src} className='content-style' />
                                </div>
                            ))}
                        </Carousel>

                        {/* Logo and Navbar */}
                        <div className='top-bar'>
                            <div className='logo'>
                                <img src='/media/landingpage/logo@300x 1.png' alt='GSO Logo' />
                            </div>
                              {/* Hamburger Menu Icon */}
                    <div className="menu-icon" onClick={toggleMenu}>
                        <img src={toAbsoluteUrl('/media/landingpage/menuIcon.png')} alt="menu icon" width={20} height={15} />
                    </div>
                            <nav className={`navbar ${isOpen ? "open" : "close"}`}>
                                <ul>
                                    <li >
                                        <a href='#accueil' onClick={closeMenu}>Accueil</a>
                                    </li>
                                    <li>
                                        <a href='#mission' onClick={closeMenu}>Notre mission</a>
                                    </li>
                                    <li>
                                        <a href='#proposition' onClick={closeMenu}>Notre proposition</a>
                                    </li>
                                    <li>
                                        <a href='/auth' onClick={closeMenu}>Se connecter</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>

                        {/* Overlay Content */}
                        <div className='overlay-content'>
                            <h1 className='slider-title'>Libérez Le Pouvoir Du Soleil Avec GSO</h1>
                            <span className='slider-text '>
                                Transformer l’énergie solaire en opportunités durables
                            </span>
                            {/* <button className='cta-button'>Contactez-Nous</button> */}
                        </div>
                    </div>
                </header>

                {/* Mission Section */}
                <section className='mission' id='mission'>
                    <h2 className='title'>Notre Mission</h2>
                    <div className='mission-cards'>
                        <div className='card'>
                            <img src={toAbsoluteUrl('/media/landingpage/icon.svg')} alt='icone' />
                            <p className='card-content'>
                                Fournir l’espace et l’expertise nécessaires aus industries, PME et grands commerces.
                            </p>
                        </div>
                        <div className='card'>
                            <img src={toAbsoluteUrl('/media/landingpage/icon-1.svg')} alt='icone' />
                            <p className='card-content'>
                                Réduire significativement leurs coûts énergétiques tout en préservant notre planète.
                            </p>
                        </div>
                    </div>
                </section>

                {/* Proposition Unique Section */}
                <section className='proposition' >
                    <h4 className='proposition-service'> Nos services </h4>
                    <div className="input-selector"></div>
                    <div className='content'>
                        <div className='details'>
                            <h2 className='title'>Notre Proposition Unique</h2>
                            <p className='text-container'>
                                <img src={toAbsoluteUrl('/media/landingpage/Imgproposition1.png')} className='icon-img' /> Espace dédié
                                dans des zones à haut rendement solaire.
                            </p>
                            <p  className='text-container'>
                                <img alt='' src={toAbsoluteUrl('/media/landingpage/Imgproposition2.png')} className='icon-img' /> Prise en
                                charge complète : de l’étude à la maintenance.
                            </p>
                            <p  className='text-container'>
                                <img loading='lazy' alt='' src={toAbsoluteUrl('/media/landingpage/Imgproposition3.png')} style={{ maxWidth: "20.117%" }} />
                                Suivi performant via plateforme et application mobile .
                            </p>
                        </div>
                        {/* <img src="solar-image.jpg" alt="Solar Panel" /> */}
                        <img
                            className='mask-group-icon'
                            loading='lazy'
                            alt=''
                            src={toAbsoluteUrl('/media/landingpage/mask-group@2x.png')}
                        />
                    </div>
                </section>

                {/* Pourquoi Choisir GSO Section */}
                <section className='why-choose' id="proposition">
                    <h4
                        className='proposition-service'
                        style={{ textAlign: 'center' }}
                    >
                        {' '}
                        Avantages et proposition de valeur{' '}

                    </h4>
                    <div className="size-scale-child"></div>
                    <h2 className='title'>Pourquoi Choisir GSO ?</h2>

                    <div className='cards'>
                        <div className='card'>
                            <div className='card-container'>
                                <h3 className='cards-title'>Réduction des coûts </h3>

                                <img
                                    className='one-icon'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/oneIcon.png')}
                                />
                            </div>

                            <p className='cards-description'>
                                Optimisez vos dépenses et votre position sur le marché avec notre solution solaire
                                clé en main.
                            </p>
                            <div className='star-shape'>
                                <img
                                    className='appointment-1-icon'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/appointment-1.svg')}
                                />
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-container'>
                                <h3 className='cards-title'>Engagement actif pour la décarbonation</h3>

                                <img
                                    className='one-icon'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/twoIcon.png')}
                                />
                            </div>
                            <p className='cards-description'>
                                Participez à la décarbonation et à la lutte contre le changement climatique grâce à
                                l énergie solaire.
                            </p>
                            <div className='input-field'>
                                <img
                                    className='house-2-1'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/house-2-1.svg')}
                                />
                            </div>
                        </div>
                        <div className='card'>
                            <div className='card-container'>
                                <h3 className='cards-title'>Accès à l’indépendance énergétiques</h3>

                                <img
                                    className='one-icon'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/threeIcon.png')}
                                />
                            </div>
                            <p className='cards-description'>
                                Assurez votre autonomie énergitique avec nos sites à haut rendement solaire, pour
                                une sécurité durable.
                            </p>
                            <div className='hired-1-1-wrapper'>
                                <img
                                    className='hired-1-1'
                                    loading='lazy'
                                    alt=''
                                    src={toAbsoluteUrl('/media/landingpage/hired-1-1.svg')}
                                />
                            </div>
                        </div>
                    </div>
                </section>

                {/* Avec GSO Section */}
                <section className='avec-gso'>
                    <div className='gso-container'>
                        <img
                            className='image-icon'
                            loading='lazy'
                            alt='Solar Panel'
                            src={toAbsoluteUrl('/media/landingpage/image@2x.png')}
                        />
                        <div style={{ marginLeft: '2rem' }}>
                            <h4 className='proposition-service'> Engagement </h4>
                            <div className="input-selector"></div>
                            <h2 className='title' style={{ textAlign: 'left' }}>
                                Avec GSO
                            </h2>
                            <p className='p-content'>
                                Soutenu par des programmes internationaux et en ligne avec les objectifs du plan
                                solaire Tunisien, GSO vise à réduire les émissions mondiales de gaz à effet de
                                serre..
                            </p>
                            <div className='avec-gso-container'>
                                <img
                                    loading='lazy'
                                    alt='time-img'
                                    className='time-icon'
                                    src={toAbsoluteUrl('/media/landingpage/time.png')}
                                />
                                <p className='optimisez-vos-dpenses'>
                                    Améliorez votre gestion budgétaire et renforcez votre position concurrentielle
                                    grâce à notre solution solaire tout-en-un.
                                </p>
                            </div>
                        </div>
                    </div>
                </section>
                <div className='w-100'>
                    {' '}
                    <KTCard>
                        <EngageWidget10 className='h-md-100' />
                    </KTCard>
                </div>

                {/* Contact Section */}
                <section className='contact' id="connexion">
                    <div>
                        <div className='contact-section'>
                            <img
                                className='contact-img'
                                alt=''
                                src={toAbsoluteUrl('/media/landingpage/image-1@2x.png')}
                            />
                            <div className='contact-container'>
                                <h2 className='title'>Contactez-Nous</h2>
                                <p className='optimisez-vos-dpenses'>
                                    {' '}
                                    Vous souhaitez nous contacter pour une demande d'information ou nous faire part de
                                    vos remarques...
                                </p>

                                <form className='contact-form'>
                                    <div className='form-input'>
                                        <input type='text' placeholder='Nom' required value={nom}
                                            onChange={handleNomChange}
                                        />
                                        <input type='email' placeholder='Email' required value={email}
                                            onChange={handleEmailChange}
                                        />
                                    </div>

                                    <textarea placeholder='Question' value={question}
                                        onChange={handleQuestionChange}
                                    ></textarea>
                                    <button type='submit' onClick={handleSubmit}>Envoyer</button>
                                </form>
                            </div>
                            <div className='user-info'>
                                <div className='wrapper'>
                                    <div className='info'>
                                        <div className='text'>
                                            <h2 className='slaheddine-el-guetari'>Slaheddine El Guetari</h2>
                                            <div className='slaheddineelguetarigmailcom'>
                                                slaheddine.elguetari@gmail.com
                                            </div>
                                        </div>
                                    </div>
                                    <div className='info1'>
                                        <button className='mdishovel'>
                                            <img
                                                className='phone-icon'
                                                alt=''
                                                src={toAbsoluteUrl('/media/landingpage/phone.svg')}
                                            />
                                        </button>
                                        <div className='decagon-dwell'>+216 98 531 626</div>
                                    </div>
                                    <div className='info2'>
                                        <button className='mdishovel1'>
                                            <img
                                                className='map-pin-icon'
                                                alt=''
                                                src={toAbsoluteUrl('/media/landingpage/mappin.svg')}
                                            />
                                        </button>
                                        <div className='rue'>Rue ............</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* Footer */}
                <footer className='footer'>
                    <div className='logo-blanc300x-1-wrapper'>
                        <img
                            className='logo-blanc300x-11'
                            alt=''
                            src={toAbsoluteUrl('/media/landingpage/logo-blanc300x-1@2x.png')}
                        />
                    </div>
                    <p className='p-footer'>
                        Prêt à faire un pas vers un avenir plus durable? que vous soyez un professionnel à la
                        recherche d’une solution énergétique économique, GSO est votre partenaire idéal.
                    </p>
                    <div className='footer-container'>
                        <div className='condition-branch'>
                            <img className='icon3' alt='' src={toAbsoluteUrl('/media/landingpage/icon-2.svg')} />

                            <div className='input-array'>+216 98 531 626</div>
                        </div>
                        <div className='condition-branch'>
                            <img className='icon3' alt='' src={toAbsoluteUrl('/media/landingpage//icon-3.svg')} />

                            <div className='input-array'> {' '} slaheddine.elguetari@gmail.com</div>
                        </div>
                    </div>

                </footer>
            </div>
        </>
    )
}
export { Landing }